import { SyntheticEvent, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import QuiltedImageList from '../../components/QuiltedImageList'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography
} from '@mui/material'

export const Home = () => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }
  return (
    <Container>
      <Typography
        variant="h2"
        textAlign="center"
        sx={{ fontFamily: 'Italianno' }}
      >
        Paulina
      </Typography>
      <Accordion
        sx={{ backgroundColor: '#209cee' }}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ flexShrink: 0 }} variant="h6">
            Te ví
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Y dije, ¡es hermosa! suspiré. Me robó el aliento por un momento. Hoy
            te sigo viendo y me sigo sintiendo como esa primera vez que dije en
            mi cabeza -esa muchacha me gusta. Todo de ella me gusta, mira su
            cabello, sus ojos, su color de piel, su cuerpo, su manera de
            sonreir, sus labios. Para mí es perfecta solo con verla
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: '#209cee' }}
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ flexShrink: 0 }} variant="h6">
            Te hablé
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Su voz me fascinó, su dulcura al hablar, su tímidez. Sus señales
            decían "me incomoda pero me gusta". Conocí una mujer sincera y
            respetuosa, segura de lo que quiere lograr. Responsable y dedicada a
            si misma. Busca lo mejor de cada quien y se fija solo en lo bueno de
            las personas. Ella vió muchas cosas buenas en mi y eso me atrapó. Me
            enamoró con cada palabra que decía, con cada mensaje de aliento y
            con cada coqueteo que tal vez inconcientemente hacía conmigo.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: '#209cee' }}
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ flexShrink: 0 }} variant="h6">
            Te conquisté
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Persistí y persistí, fuí necio, fuí constante, o al menos eso creo.
            Hice todo para demostrar mi interés. Me sentía correspondido y por
            eso no me quería dar por vencido. Te dí todo lo que pude darte para
            demostrar que mi amor es sincero, que eres con quiero estar y dar el
            100% de mí. Detalles, flores, atención, unas barritas de proteina y
            keratina, un mensaje de te extraño, te quiero, eres hermosa, me hace
            feliz verte. Quiero repetir cada una de esas cosas una y mil veces.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: '#209cee' }}
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ flexShrink: 0 }} variant="h6">
            La propuesta
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Finalmente te hice mi novia, quería que fuera perfecto. Pensé y
            pensé pero había poco tiempo de planear la mejor propuesta. Hice mi
            mejor esfuerzo y te sentí encantada ese día. Me sentí tan feliz de
            todo, me sentí en las nubes.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: '#209cee' }}
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography sx={{ flexShrink: 0 }} variant="h6">
            El reloj
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Tenía que encontrar un detalle que significara algo cada que lo
            vieras. Pero tambien que demostrara que quiero darte lo que
            necesites. Quería darte algo útil que refleje que estoy pendiente de
            cada detalle que necesitas. Quería decirte que te amo y que estoy
            para tí 100%. Que mi compromiso es desde el día uno que te hice mi
            novia. Porque no quiero una relacion cortica de "vamos a ver que
            pasa". No, yo quiero una relación de vamos a hacer que pase.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: '#209cee' }}
        expanded={expanded === 'panel6'}
        onChange={handleChange('panel6')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography sx={{ flexShrink: 0 }} variant="h6">
            La carta
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Me escribiste una carta y volé, me sentí en el centro de todo el
            maldito universo. Me sentí como nunca antes me había sentido. Te
            abriste y me dijiste cosas que no le has dicho a nadie. Te creo cada
            palabra y siento el amor que tienes por mi. Por lo nuestro.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: '#209cee' }}
        expanded={expanded === 'panel7'}
        onChange={handleChange('panel7')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography sx={{ flexShrink: 0 }} variant="h6">
            La consentida
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Así le llamo a esa niña que siempre quiere tener la atención, que se
            pone triste si no le doy la atención que quiere. Y a esa niña la amo
            con todo mi corazón. Me mueve el piso y me demuestra que puedo
            perderla en cualquier momento. Y busco las mil formar de verla
            contenta de nuevo. Juro que amo a esa niña consentida.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: '#209cee' }}
        expanded={expanded === 'panel8'}
        onChange={handleChange('panel8')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography sx={{ flexShrink: 0 }} variant="h6">
            Yo
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            No soy perfecto mi amor, tengo mil defectos. No estoy al 100% todos
            los días pero créeme q quiero estarlo para tí. Quiero ser el mejor
            hombre del mundo para ti. Quiero ser el que se da cuenta cuando
            tienes un cabello menos. Así de detallista quiero ser para ti. Pero
            soy muchas cosas malas, soy necio, terco, un poco orgulloso. Sin
            embargo, tambien soy muchas cosas buenas que tu misma me hiciste dar
            cuenta.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: '#209cee' }}
        expanded={expanded === 'panel8'}
        onChange={handleChange('panel8')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography sx={{ flexShrink: 0 }} variant="h6">
            Quiero
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Quiero cargarte todas las noches a la cama si te quedas dormida en
            el sofá. Quiero darte un carro y una casa, una familia. Quiero
            hacerte la mejor propuesta de matrimonio. Que tengas la mejor boda q
            ambos soñamos. Que tengamos hijos. Quiero ser el mejor padre y
            esposo. Quiero atenderte cada día como una princesa. Quiero no
            fallarte NUNCA.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: '#209cee' }}
        expanded={expanded === 'panel9'}
        onChange={handleChange('panel9')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          <Typography sx={{ flexShrink: 0 }} variant="h6">
            Nuestros días
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Son felíces, son alegres, son de risas y emociones. Hay días
            tristes, hay días malos. No importa si hay tropiezos. Nos levantamos
            de inmediato. Hablamos y conversamos, nos amamos y estamos
            dispuestos a seguir adelante no importa lo que pase. Nos estamos
            conociendo y es normal confundir las cosas a veces.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: '#209cee' }}
        expanded={expanded === 'panel10'}
        onChange={handleChange('panel10')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
        >
          <Typography sx={{ flexShrink: 0 }} variant="h6">
            Nosotros
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <QuiltedImageList />
        </AccordionDetails>
      </Accordion>
    </Container>
  )
}
