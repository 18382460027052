import './sass/app.scss'
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom'
import 'moment/locale/es'
import { Home } from './screens'
import 'normalize.css'
import { ThemeProvider, createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#209cee'
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    }
  },
  typography: {
    fontFamily: 'Ropa Sans',
    fontSize: 18
  }
})

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home />
    },
    {
      path: '*',
      loader: () => redirect('/')
    }
  ])

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </div>
  )
}

export default App
