import * as React from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`
  }
}

export default function QuiltedImageList() {
  return (
    <ImageList
      sx={{ width: '100%', height: 450 }}
      variant="quilted"
      cols={4}
      rowHeight={121}
    >
      {itemData.map((item) => (
        <ImageListItem
          key={item.img}
          cols={item.cols || 1}
          rows={item.rows || 1}
        >
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  )
}

const itemData = [
  {
    img: './images/Pau001.jpeg',
    title: 'Pau y Yisus',
    rows: 2,
    cols: 2
  },
  {
    img: './images/Pau002.jpeg',
    title: 'Pau y Yisus'
  },
  {
    img: './images/Pau003.jpeg',
    title: 'Pau y Yisus'
  },
  {
    img: './images/Pau004.jpeg',
    title: 'Pau y Yisus',
    cols: 2
  },
  {
    img: './images/Pau011.jpeg',
    title: 'Pau y Yisus',
    cols: 2
  },
  {
    img: './images/Pau005.jpeg',
    title: 'Pau y Yisus',
    author: '@arwinneil',
    rows: 2,
    cols: 2
  },
  {
    img: './images/Pau006.jpeg',
    title: 'Pau y Yisus'
  },
  {
    img: './images/Pau007.jpeg',
    title: 'Pau y Yisus'
  },
  {
    img: './images/Pau008.jpeg',
    title: 'Pau y Yisus',
    rows: 2,
    cols: 2
  },
  {
    img: './images/Pau009.jpeg',
    title: 'Pau y Yisus'
  },
  {
    img: './images/Pau010.jpeg',
    title: 'Pau y Yisus'
  },
  {
    img: './images/Pau011.jpeg',
    title: 'Pau y Yisus',
    cols: 2
  }
]
